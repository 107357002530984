#bp_widget{
    position: fixed; 
    float: right; 
    right: 0px; 
    bottom: 7vh; 
    width: 40vh; 
    height: 60vh;
}

#bp_widget_iframe{
    position: inherit; 
    bottom: 7vh; 
    border: 1px solid rgba('224; 224; 224; 1'); 
    width: 40vh; 
    height: 60vh; 
}

#bp_widget_button{
    position: inherit; 
    float: right; 
    right: 1vh; 
    bottom: 1vh;
    background-color: #31BD58;
    color: white;
}
#bp_widget_iframe.moved_out{
    right: -535px;
    animation: slide-out 0.5s forwards;
}
#bp_widget_iframe.moved_in{
    right: 50px;
    animation: slide-in 0.5s forwards;
}
@keyframes slide-in {
    0% {
      right: 0px; 
    }
    100% {
      right: -535px;
    }
  }
  @keyframes slide-out {
    0% {
      right: -535px; /* Начальное положение элемента за пределами экрана */
    }
    100% {
      right: 0px; /* Конечное положение элемента внутри экрана */
    }
  }