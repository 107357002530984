.select-box {
  display: flex;
  align-items: center;
}

.select-box__text {
  cursor: pointer;
}

.select-box__text_active {
  color: #1976d2;
}
