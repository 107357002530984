.App {
  text-align: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.content{
  margin-left: calc(6.7% + 24px) !important;
  margin-right: 24px;
  width: calc(100% - 6.7% - 48px);
  padding: 0;
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* .ps-sidebar-root-test-id{ */
  /* background-color: #2d8cff; */
/* } */
.css-dip3t8{
  /* background: linear-gradient(180deg, #2F76E6 0%, #0C4ED1 100%); */
  background-color: #538ec0 !important;
  opacity: 1;
}
.ps-menu-button:hover {
  background-color: unset !important;
}
.ps-menuitem-root{
  background-color: unset !important;
}
.ps-menu-root ul{
  align-items: center;
}
.ps-menu-icon :hover{
  color: black;
}
.MuiSvgIcon-root{
  color: inherit;
}
.Nav > a > div > svg{
  font-size: 40px !important;
}

.Nav > a{
  color: inherit;
}

.Nav {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.8rem;
  grid-auto-flow: row dense;
  align-items: center;
  justify-items: center;
  min-height: 6vh;
  font-weight: bold;
  /* background-color: "#538ec0" !important; */
}

.Nav > a:hover{
  color: white !important;
}
p{
  padding: 0.5px !important; 
  margin-top: 1.5px !important;
  margin-bottom: 1.5px !important;
}
a{
  text-decoration: none;
}
.logo.normal{
  transform:scale(1);
  transition:transform 0.3s ease-out;
}
.logo.scaled{
  transform:scale(1.4);
  transition:transform 0.3s ease-out;
}
.p.fade_in{
  opacity: 0;
  transition: opacity 0.5s;
}
.p.fade_out{
  opacity: 1;
  transform: opacity 0.5s;
}
.MuiContainer-root {
  width: 100% !important;
  max-width: 100% !important;
  min-width: 100% !important;
    /* padding: 0 !important; */
    /* width: 100%; */
    /* margin-left: auto; */
    /* box-sizing: border-box; */
    /* margin-right: auto; */
    /* display: block; */
    /* padding-right: 16px; */
}
.table_title.fadeout{
  opacity: 1;
  transition: opacity 0.5s;
}
.table_title.faded{
  opacity: 0;
}
a.active{
  color: white !important;
}

.page-enter {
  opacity: 0;
  /* transform: translateX(100%); */
}

.page-enter-active {
  opacity: 1;
  /* transform: translateX(0); */
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}
.MuiFormHelperText-root{
  /* position: absolute; */
  height: 0;
  /* width: 0; */
  color: red !important;
  padding: 0;
  margin: 0 !important;
}
.MuiOutlinedInput-root{
  border-radius: 10px !important;
  padding: 0.6vh 0.6vh 0.6vh 0.6vh !important;
  border-color: #ebedef !important;
}
.MuiAutocomplete-root > div > div{
  height: 5.6vh;
}
::-webkit-scrollbar {
  width: 9px;
  max-height: 10px;
  margin-block-end: 20px;
  
}
/* Track */
::-webkit-scrollbar-track {
  background: none; 
  margin: 50px !important; 
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #D0D5DD; 
border-radius: 150px;
background-clip: padding-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #D0D5DD; 
}