#sidebar{
    height: 100vh;
    background: linear-gradient(180deg, #3D88FE 0%, #0D4FD2 100%);
    position: fixed;
    z-index: 10;
}
.Nav > a > div > svg{
    font-size: 40px !important;
  }
.Nav > a{
    color: inherit;
}
.Nav > ul{
    width: fit-content;
    
}
.Nav {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.8rem;
    grid-auto-flow: row dense;
    align-items: center;
    justify-items: center;
    min-height: 6vh;
    font-weight: bold;
    width: 100% !important;
}
p{
    padding: 0.5px !important; 
    margin-top: 1.5px !important;
    margin-bottom: 1.5px !important;
}
a{
    text-decoration: none;
}
.logo.normal{
    transform:scale(1);
    transition:transform 0.3s ease-out;
}
.logo.scaled{
    transform:scale(1.4);
    transition:transform 0.3s ease-out;
}
.p.fade_in{
    opacity: 0;
    height: 2.5vh;
    transform:translate(-10px, 0);
    transition: opacity 0.2s, transform 0.5s;
}
.p.fade_out{
    opacity: 1;
    transform:translate(0px, 0);
    transition: opacity 0.7s, transform 0.5s;
}
.submenu > a > .ps-submenu-expand-icon{
    display: none;
}
.submenu > a > .ps-menu-suffix > svg{
    height: 4vh;
    width: 4vh;
}
.Nav > ul > li > a{
    padding: 0 !important;
}
.Nav > ul > li > a > span > svg{
    font-size: 40px;
}
.Nav > ul > li{
    padding-bottom: 25px;
    padding-top: 10px;
}
.Nav > ul > li > a:hover{
    color: white !important;
}
.css-1t8x7v1 >.ps-menu-button:hover {
    background-color: unset !important;
}
#sidebar > div > .Nav > ul > li > a.ps-menu-button.active{
    color: white;
}
#sidebar{
    width: 6.7%;
}
#sidebar:hover{
    width: 15.5%;
}
#sidebar:hover p{
    opacity: 1;
    transform:translate(0px, 0);
    transition: opacity 0.5s, transform 0.5s;
}
#sidebar:hover .logo{
    transform:scale(1.4);
    transition:transform 0.3s ease-out;
}
.css-1wvake5{
    min-width: unset !important;
    width: unset !important;
}